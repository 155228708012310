body {
  max-width: 100%;
}
p {
  /*margin: 50px;*/
  text-align: left;
}
.container {
  display: flex;
  /*max-width: 100%;*/
}

.side-bar {
  min-width: 10vw;
  padding-left: 10px;
  text-align: left;
}
.title {
  position: fixed;
  /* top: -25px; */
  left: 0px;
}
.App {
  text-align: center;
  max-width: 90vw;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media screen and (max-device-width: 1000px) {
  .container {
    flex-direction: column;
  }
  .article-title {
    padding-top: 100px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

